import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import QuotesWidget from "./QuotesWidget";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "quoteswidget"
    }}>{`QuotesWidget`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={QuotesWidget} mdxType="Props" />
    <h2 {...{
      "id": "insurance-types"
    }}>{`Insurance Types`}</h2>
    <ul>
  <li>Auto</li>
  <li>Home</li>
  <li>Renters</li>
    </ul>
    <h2 {...{
      "id": "compact-basic-usage"
    }}>{`Compact Basic Usage`}</h2>
    <p>{`This is the default style. You  don't need to define anything more than the title and the description.`}</p>
    <h3 {...{
      "id": "auto-insurance"
    }}>{`Auto Insurance`}</h3>
    <Playground __position={2} __code={'<QuotesWidget type=\"auto\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuotesWidget,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuotesWidget type="auto" mdxType="QuotesWidget" />
    </Playground>
    <h3 {...{
      "id": "home-insurance"
    }}>{`Home Insurance`}</h3>
    <Playground __position={3} __code={'<QuotesWidget type=\"home\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuotesWidget,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuotesWidget type="home" mdxType="QuotesWidget" />
    </Playground>
    <h3 {...{
      "id": "renters-insurance"
    }}>{`Renters Insurance`}</h3>
    <Playground __position={4} __code={'<QuotesWidget type=\"renters\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuotesWidget,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuotesWidget type="renters" mdxType="QuotesWidget" />
    </Playground>
    <h2 {...{
      "id": "fullwidth-basic-usage"
    }}>{`FullWidth Basic Usage`}</h2>
    <p>{`To use the Full Width style, you must specify the `}<em parentName="p">{`fullWidth`}</em>{` property as you can see in the example.`}</p>
    <h3 {...{
      "id": "auto-insurance-1"
    }}>{`Auto Insurance`}</h3>
    <Playground __position={5} __code={'<QuotesWidget type=\"auto\" fullWidth />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuotesWidget,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuotesWidget type="auto" fullWidth mdxType="QuotesWidget" />
    </Playground>
    <h3 {...{
      "id": "home-insurance-1"
    }}>{`Home Insurance`}</h3>
    <Playground __position={6} __code={'<QuotesWidget type=\"home\" fullWidth />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuotesWidget,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuotesWidget type="home" fullWidth mdxType="QuotesWidget" />
    </Playground>
    <h3 {...{
      "id": "renters-insurance-1"
    }}>{`Renters Insurance`}</h3>
    <Playground __position={7} __code={'<QuotesWidget type=\"renters\" fullWidth />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuotesWidget,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuotesWidget type="renters" fullWidth mdxType="QuotesWidget" />
    </Playground>
    <h3 {...{
      "id": "colored-version"
    }}>{`Colored version`}</h3>
    <Playground __position={8} __code={'<QuotesWidget type=\"auto\" fullWidth colored />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuotesWidget,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuotesWidget type="auto" fullWidth colored mdxType="QuotesWidget" />
    </Playground>
    <h2 {...{
      "id": "custom-usage"
    }}>{`Custom Usage`}</h2>
    <h3 {...{
      "id": "compact"
    }}>{`Compact`}</h3>
    <Playground __position={9} __code={'<QuotesWidget title=\"Custom title\" description=\"Custom description.\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuotesWidget,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuotesWidget title="Custom title" description="Custom description." mdxType="QuotesWidget" />
    </Playground>
    <h3 {...{
      "id": "fullwidth"
    }}>{`FullWidth`}</h3>
    <Playground __position={10} __code={'<QuotesWidget\n  fullWidth\n  title=\"Custom title\"\n  description=\"Custom description.\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      QuotesWidget,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <QuotesWidget fullWidth title="Custom title" description="Custom description." mdxType="QuotesWidget" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      